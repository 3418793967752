<template>
    <div class="edit-profile-detail">
        <StackRouterHeaderBar left-button="close" title="학력정보" :showTitle="true" />
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div v-if="type === 'high_school'">
            <div class="section">
                <div class="section-title required" v-html="`학교`" />
                <TextareaWithX v-model="highSchool" placeholder="학교명 입력" :isInputMode="true" />
            </div>
        </div>
        <div v-else>
            <div class="section" v-for="item in univStructure" :key="item.key">
                <div class="section-title" :class="{ required: item.required }" v-html="item.name" />
                <div class="search-box-wrapper" @click="onClickSearch(item.key)">
                    <div class="search-box c-black" v-if="school[item.key]" v-html="school[item.key].name" />
                    <div class="search-box" v-else>
                        <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" />
                        <span v-html="$translate(`PLACEHOLDER_SEARCH_EDUCATION_${item.key.toUpperCase()}`)" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="section">
            <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="onClickSelector(select.id)"
                >
                    <img
                        class="m-r-4"
                        :src="
                            require(`@/assets/images/icons/${
                                selectedShowMethod === select.id ? 'radio_on' : 'radio_off'
                            }.png`)
                        "
                        width="24px"
                        height="24px"
                    />
                    <span v-html="select.key" />
                </div>
            </div>
        </div> -->
        <!-- <div class="section">
            <div class="section-title required">학력상태</div>
            <SelectInput
                @selected="setSelectedEduStatus"
                :alreadySelected="educationStatusSelected ? educationStatusSelected.id : null"
                :options="educationStatus"
                v-if="showInput"
            />
        </div> -->
        <!-- <div class="section">
            <div class="section-title">입학/졸업 연도</div>
            <div class="year">
                <TextareaWithX v-model="start" placeholder="입학 연도(ex.2000)" :type="'tel'" :isInputMode="true" />
                <span class="f-14 m-l-4 m-r-4">-</span>
                <TextareaWithX v-model="end" placeholder="졸업 연도(ex.2000)" :type="'tel'" :isInputMode="true" />
            </div>
        </div> -->
        <BottomButton :disabled="disabled" @click="submit" />
    </div>
</template>

<script>
import SelectInput from '../../../components/app/SelectInput'

export default {
    name: 'EditProfileDetailEducation',
    components: {
        SelectInput,
    },
    props: {
        type: String,
        profile: Array,
    },
    data: () => ({
        // selectedShowMethod: null,
        // educationStatusSelected: null,
        // start: null,
        // end: null,
        highSchool: null,
        school: {
            university: null,
            // college: null,
            major: null,
        },
        showInput: false,
    }),
    computed: {
        // profile() {
        //     return this.$store.getters.profile
        // },
        disabled() {
            if (this.type === 'high_school') {
                // return !(this.highSchool && this.educationStatusSelected && this.start && this.end)
                return !(this.highSchool)
            }
            return !(
                // [0, 1].includes(this.selectedShowMethod) &&
                this.school.university
                // 연도는 필수는 아니지만 연도는 두개 다 입력해야 함
                // ((this.start && this.end) || !(this.start || this.end)) &&
                // this.educationStatusSelected
            )
        },
        // privacySetting() {
        //     return this.profile.privacy_setting || {}
        // },
        univStructure() {
            return [
                {
                    key: 'university',
                    name: '학교',
                    required: true,
                },
                // {
                //     key: 'college',
                //     name: '단과대',
                // },
                {
                    key: 'major',
                    name: '전공(학과)',
                },
            ]
        },
        selectors() {
            return [
                {
                    id: 1,
                    key: `${this.type === 'high_school' ? '공개' : '전체 공개'} ${
                        this.schoolFullName ? this.schoolFullName.all : ''
                    }`,
                },
                {
                    id: 0,
                    key: `${this.type === 'high_school' ? '비공개(매니저에게만 공개)' : '부분 공개'} ${
                        this.schoolFullName ? this.schoolFullName.simple : ''
                    }`,
                },
            ]
        },
        schoolFullName() {
            const school =
                this.type === 'high_school' ? false : this.school.university && this.school.college && this.school.major
            if (!school) return false

            return {
                all: ` : ${this.school.university.name} ${this.school.college.name} ${this.school.major.name}`,
                simple: ` : ${this.school.university.name}`,
            }
        },
        // educationStatus() {
        //     const res = Object.keys(this.$profile.userEduStatus).map(key => this.$profile.userEduStatus[key])
        //     return this.type === 'high_school' ? res.filter(item => item.highSchool) : res
        // },
    },
    methods: {
        init() {
            const school =
                this.type === 'high_school' ? this.profile.high_school_name : this.profile[`${this.type}_u_name`]
            if (!school) {
                this.showInput = true
                return
            }

            this.highSchool = this.type === 'high_school' ? this.profile.high_school_name : null
            if (this.profile[`${this.type}_u_name`]) {
                this.school.university = {
                    id: this.profile[`${this.type}_u_id`],
                    name: this.profile[`${this.type}_u_name`],
                }
            }
            // if (this.profile[`${this.type}_c_name`]) {
            //     this.school.college = {
            //         id: this.profile[`${this.type}_c_id`],
            //         name: this.profile[`${this.type}_c_name`],
            //     }
            // }
            if (this.profile[`${this.type}_m_name`]) {
                this.school.major = {
                    id: this.profile[`${this.type}_m_id`],
                    name: this.profile[`${this.type}_m_name`],
                }
            }

            // try {
            //     this.selectedShowMethod =
            //         this.type === 'high_school'
            //             ? this.privacySetting.show_high_school_name
            //             : this.privacySetting[`show_${this.type}_c_name`]
            //     this.start = this.profile[`${this.type}_start`] || ''
            //     this.end = this.profile[`${this.type}_end`] || ''
            //     this.educationStatusSelected = this.$profile.userEduStatus[
            //         Object.keys(this.$profile.userEduStatus).find(
            //             key => this.$profile.userEduStatus[key].id === this.profile[`${this.type}_status`]
            //         )
            //     ]

            //     this.showInput = true
            // } catch (e) {
            //     console.log(e)
            // }
        },
        // onClickSelector(id) {
        //     this.selectedShowMethod = id
        // },
        // setSelectedEduStatus(item) {
        //     this.educationStatusSelected = item
        // },
        async onClickSearch(type) {
            const item = await this.$modal.custom({
                component: 'ModalProfileItemSelector',
                options: {
                    model: type,
                },
            })
            if (item) {
                this.school[type] = item
            }
        },
        async submit() {
            const { university, major } = this.school
            // const privacy = this.privacySetting

            try {
                if (this.type === 'high_school') {
                    this.$set(this.profile, 'high_school_name', this.highSchool)

                    // privacy.show_high_school_name = this.selectedShowMethod
                    // privacy.show_high_school_end = this.selectedShowMethod
                    // privacy.show_high_school_start = this.selectedShowMethod
                    // privacy.show_high_school_status = this.selectedShowMethod
                } else {
                    this.$set(this.profile, `${this.type}_u_id`, university.id)
                    this.$set(this.profile, `${this.type}_u_name`, university.name)
                    // this.$set(this.profile, `${this.type}_c_id`, college.id)
                    // this.$set(this.profile, `${this.type}_c_name`, college.name)
                    this.$set(this.profile, `${this.type}_m_id`, major.id)
                    this.$set(this.profile, `${this.type}_m_name`, major.name)

                    // privacy[`show_${this.type}_c_name`] = this.selectedShowMethod
                    // privacy[`show_${this.type}_m_name`] = this.selectedShowMethod
                }

                // this.$set(this.profile, `${this.type}_start`, this.start)
                // this.$set(this.profile, `${this.type}_end`, this.end)
                // this.$set(this.profile, `${this.type}_status`, this.educationStatusSelected.id)

                // this.$profile.setPrivacySettings(privacy)

                this.$stackRouter.pop()
            } catch (e) {
                console.log(e)
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.year {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .textarea-with-x {
        height: 48px;
    }
}
</style>
